<template>
  <v-app>
    <home-app-bar />

    <home-view />

    <home-footer />

    <global-snackbar />
  </v-app>
</template>

<script>
export default {
  name: "HomeLayout",

  components: {
    HomeAppBar: () => import("@/layouts/home/AppBar"),
    HomeFooter: () => import("@/layouts/home/Footer"),
    HomeView: () => import("@/layouts/home/View"),
    GlobalSnackbar: () => import("@/layouts/global/Snackbar"),
  },
};
</script>
